import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CollectList } from "../components/CollectList";
import { getCollectList } from "../modules/collects";
import { useAuth } from "../contexts/auth/auth.context";

export const CollectListContainer = ({mallParam = 'all', searchTypeParam = 'all'}) => {
  const { user, setUser, global, setGlobal } = useAuth();

  const [ currPage, setCurrPage ] = useState(1);
  const [ category, setCategory ] = useState('');
  const [ displayOrder, setDisplayOrder ] = useState('best_desc');
  const [ searchWord, setSearchWord ] = useState('');

  const { data, loading, error} = useSelector((state) => state.collectReducer.collectlist);
  const dispatch = useDispatch();

  const onDisplayOrder = (orderParm) => {
    setDisplayOrder(orderParm);
  }

  const onSearchSubmit = (searchParm) => {
    setSearchWord(searchParm);
  }

  // 컴포넌트 마운트 후 목록 요청
  //       { script: { script: doc['collect_price'].value < doc['price_floor'].value}}      
  useEffect(() => {
    let params = { size: 1000, query : { bool: { filter: [ 
    ]}}}
    if (mallParam != 'all' && searchTypeParam != 'all') {
      params = { size: 1000, query : { bool: { filter: [
        { match: { mall: `${mallParam}` }}, { match: { search_type: `${searchTypeParam}` }}
      ]}}}
    }
    else if (mallParam != 'all') {
      params = { size: 1000, query : { bool: { filter: [
        { match: { mall: `${mallParam}` }}
      ]}}}
    }
    else if (searchTypeParam != 'all') {
      params = { size: 1000, query : { bool: { filter: [
        { match: { search_type: `${searchTypeParam}` }}
      ]}}}
    }
    dispatch(getCollectList(params));
  }, [dispatch, mallParam, searchTypeParam, searchWord, displayOrder]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  return (
    <CollectList categoryParam={category} searchWordParam={searchWord} displayOrderParam={displayOrder} productsParam={data} onDisplayOrder={onDisplayOrder} onSearchSubmit={onSearchSubmit}/>
  );
}

