import React, { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { DeterminListContainer } from "../../containers/DeterminListContainer";
import { useAuth } from "../../contexts/auth/auth.context";

export const DeterminListPage = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const mallParam = searchParams.get("mall")
  const searchTypeParam = searchParams.get("search_type")

  // 인수로 넘어온값이 없는 경우 Default 값 설정
  const mall = (mallParam && mallParam.length > 0) ? mallParam : 'all';
  const searchType = (searchTypeParam && searchTypeParam.length > 0) ? searchTypeParam : 'all';

  return (
    <DeterminListContainer mallParam={mall} searchTypeParam={searchType}/>
  );
}

